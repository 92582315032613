import React from 'react';
import {
  AnaliticsIcon,
  BrandIcon,
  GlobusIcon,
  MenuIcon,
  OptionsIcon,
  OrderIcon,
  PromoIcon,
  RestaurantIcon,
  UsersIcon,
} from '../components/Icons';
import { SxProps } from '@mui/material';
import { Workday_Enum } from '../graphql/generated/graphql';

export const ROUTES = {
  home: '/',
  devPage: '/devPage', //+
  login: '/login', //+
  registration: '/registration', //+
  resetPassword: '/resetPassword', //+
  registrationOffer: '/registrationOffer', //+
  restaurant: '/restaurant/:id', // +
  restaurants: '/restaurants', // +
  addRestaurant: '/restaurants/add', // +
  editRestaurant: '/restaurants/edit/:id', // +
  orders: '/orders',
  order: '/orders/:id',
  menu: '/menu', //+ пока не используем этот роут
  addDish: '/menu/add', //+
  editDish: '/menu/edit/:id', //+
  users: '/users',
  addUser: '/users/add',
  editUser: '/users/edit/:id',

  adminOffers: '/offers',
  addAdminOffers: '/offers/add',
  editAdminOffers: '/offers/edit/:id',
  adminOffer: '/offers/edit-dishes/:id',

  offers: '/main-offers',
  offersInfo: '/main-offers/:id',

  applications: '/applications',
  application: '/applications/:id',
  brands: '/brands',
  brand: '/brands/:id',

  settings: '/settings',
  translations: '/translations',
  addBrands: '/brands/add', //+
  editBrands: '/brands/edit/:id', //+

  privacyPolicy: '/privacy-policy',
  agreement: '/agreement',

  restaurantsWithoutBrand: '/restaurantsWithoutBrand',
  restaurantWithoutBrand: '/restaurantsWithoutBrand/:id',
};

export const CONTENT_WIDTH = '60%';
export const MAX_WIDTH = '800px';

export const TYPE_MODAL = {
  TEST: 'test',
};

export const DEFAULT_DROPZONE_OPTIONS = {
  maxSize: 5242880, // max size 5 mb
  multiple: false,
  accept: {
    'image/jpeg': [],
    'image/png': [],
  },
};

export const EXCEL_DROPZONE_OPTIONS = {
  maxSize: 5242880, // max size 5 mb
  multiple: false,
  accept: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-excel': [],
  },
};

export const SUPER_ADMIN_NAVIGATION_AUTHS = [
  {
    id: '1',
    route: ROUTES.applications,
    label: 'web_nav_requests',
    icon: <OrderIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '2',
    route: ROUTES.brands,
    label: 'web_nav_brands',
    icon: <BrandIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '3',
    route: ROUTES.offers,
    label: 'web_nav_superadmin_offers',
    icon: <OrderIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '4',
    route: ROUTES.settings,
    label: 'web_nav_settings',
    icon: <OptionsIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '5',
    route: ROUTES.translations,
    label: 'web_nav_translates',
    icon: <GlobusIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '6',
    route: ROUTES.restaurantsWithoutBrand,
    label: 'web_nav_restaurants_without_brands',
    icon: <AnaliticsIcon />,
    badge: false,
    badgeContent: 0,
  },
];
export const ADMIN_NAVIGATION_AUTHS = [
  {
    id: '1',
    route: ROUTES.restaurants,
    label: 'web_nav_restaurants',
    icon: <RestaurantIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '2',
    route: ROUTES.orders,
    label: 'web_nav_orders',
    icon: <OrderIcon />,
    badge: true,
    badgeContent: 22,
  },
  {
    id: '3',
    route: ROUTES.addDish,
    label: 'web_nav_menu',
    icon: <MenuIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '4',
    route: ROUTES.users,
    label: 'web_nav_users',
    icon: <UsersIcon />,
    badge: false,
    badgeContent: 0,
  },
  {
    id: '5',
    route: ROUTES.adminOffers,
    label: 'web_nav_offers',
    icon: <PromoIcon />,
    badge: false,
    badgeContent: 0,
  },
];

export const MODAL_NAME = {
  DELETE_BRAND: 'DeleteBrandModal',
  ADD_ELEMENT: 'AddElementModal',
  ARE_YOU_SURE: 'AreYouSureModal',
  ADD_CATEGORY: 'AddCategory',
  CATEGORIES: 'Categories',
  REJECTED: 'RejectedModal',
  COMPLETED_SUCCESS: 'CompletedSuccessOrderModal',
  COMPLETED_REJECTED: 'CompletedRejectedOrderModal',
  UNSUCCESSFULLY_CLOSED: 'UnsuccessfullyClosed',
};

export const SCHEDULE_TYPES = {
  SINGLE: 'STATIC',
  VARIOUS: 'FLEXIBLE',
};

export const TIME_OPTIONS = [
  { id: '01:00', label: '01:00' },
  { id: '02:00', label: '02:00' },
  { id: '03:00', label: '03:00' },
  { id: '04:00', label: '04:00' },
  { id: '05:00', label: '05:00' },
  { id: '06:00', label: '06:00' },
  { id: '07:00', label: '07:00' },
  { id: '08:00', label: '08:00' },
  { id: '09:00', label: '09:00' },
  { id: '10:00', label: '10:00' },
  { id: '11:00', label: '11:00' },
  { id: '12:00', label: '12:00' },
  { id: '13:00', label: '13:00' },
  { id: '14:00', label: '14:00' },
  { id: '15:00', label: '15:00' },
  { id: '16:00', label: '16:00' },
  { id: '17:00', label: '17:00' },
  { id: '18:00', label: '18:00' },
  { id: '19:00', label: '19:00' },
  { id: '20:00', label: '20:00' },
  { id: '21:00', label: '21:00' },
  { id: '22:00', label: '22:00' },
  { id: '23:00', label: '23:00' },
  { id: '24:00', label: '00:00' },
];

export const DEFAULT_PAGINATION_OPTIONS = {
  limit: 10,
  offset: 0,
  itemsCount: 10,
  page: 1,
};

export const LS = {
  TOKEN_BYDE_ADMIN: 'token-byde-admin',
};

// ! FOR TABLE LIST ==========================================
interface InterfaceTranslationTableData {
  id: string;
  label: string;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
  sx?: SxProps;
}

export const TRANSLATION_TABLE_OPTIONS: InterfaceTranslationTableData[] = [
  {
    id: '1',
    label: 'web_translations_table_column_1',
    align: 'left',
    sx: {
      maxWidth: '200px',
    },
  },
  {
    id: '2',
    label: 'web_translations_table_column_2',
    align: 'left',
    sx: {
      width: '100%',
    },
  },
  // {
  //   id: '4',
  //   label: 'Ukrainian',
  //   align: 'left',
  // },
  // {
  //   id: '4',
  //   label: 'Franch',
  //   align: 'left',
  // },
  { id: '5', label: '', align: 'right' },
];
// ! FOR TABLE LIST ==========================================

export const INIT_SCHEDULES_VARIOUS = [
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_1',
    day: Workday_Enum.Monday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_2',
    day: Workday_Enum.Tuesday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_3',
    day: Workday_Enum.Wednesday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_4',
    day: Workday_Enum.Thursday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_5',
    day: Workday_Enum.Friday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_6',
    day: Workday_Enum.Saturday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_7',
    day: Workday_Enum.Sunday,
  },
];

export enum COUNTER_TYPES {
  MINUS = 'minus',
  PLUS = 'plus',
}

export enum REVIEW_TYPES {
  accurateRating = 'accurateRating',
  eventMatchRating = 'eventMatchRating',
  cuisineRating = 'cuisineRating',
  serviceRating = 'serviceRating',
}

export const REVIEW_OPTIONS = [
  REVIEW_TYPES.accurateRating,
  REVIEW_TYPES.eventMatchRating,
  REVIEW_TYPES.cuisineRating,
  REVIEW_TYPES.serviceRating,
];

export enum GENERAL_SETTINGS {
  hoursBeforeAdviceAppears = 'hours_before_advice_appears',
  filterOffersAmountPersonsFrom = 'filter_offers_amount_persons_from',
  filterOffersAmountPersonsTo = 'filter_offers_amount_persons_to',
  basicEventAmountPersons = 'basic_event_amount_persons',
  minEventAmountPersons = 'min_event_amount_persons',
  maxEventAmountPersons = 'max_event_amount_persons',
  basicBudgetPerPerson = 'basic_budget_per_person',
  minBudgetPerPerson = 'min_budget_per_person',
  maxBudgetPerPerson = 'max_budget_per_person',
  contactPhone = 'contact_phone',
}

export const LINK_FOR_DOWNLOAD_EXCEL =
  'https://byde.demo.malevichstudio.com/files/dish-import.sample..xlsx';
