import { useFormikContext } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextFieldFormik } from '../TextFieldFormik/TextFieldFormik';

type Props = {
  name: string;
  geometryName: string;
  size?: 'medium' | 'small' | 'large';
  label: string;
  placeholder?: string;
  minRows?: number;
  errorMessage?: string;
};

export const GoogleAutocompleteFormik = ({
  name,
  geometryName,
  size,
  label,
  placeholder,
  minRows,
  errorMessage,
}: Props) => {
  const { setFieldValue } = useFormikContext();

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setFieldValue(geometryName, { lat: lat, lng: lng });
      setFieldValue(name, place.formatted_address);
    },
    options: {
      componentRestrictions: { country: ['us', 'ua'] },

      fields: ['ALL'],
      types: ['address'],
    },
    language: 'en',
  });

  return (
    <TextFieldFormik
      name={name}
      size={size}
      label={label}
      placeholder={placeholder}
      minRows={minRows}
      inputRef={ref}
      errorMessage={errorMessage}
    />
  );
};
